





































































































































































































































import Vue from 'vue';

export default Vue.extend({
  props: {
    selectedMenuItem: {
      type: String,
      required: true,
      default: 'reactions',
    },
    currentSession: {
      type: Object,
      required: true,
    },
    theme: {
      type: Object,
      required: true,
    },
    newQuestionCount: {
      type: Number,
      required: true,
    },
    newPollCount: {
      type: Number,
      required: true,
    },
    clickHandler: {
      type: Function,
      required: true,
    },
    hideReactionMenuItem: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
