var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"block inset-x-0 bottom-0 z-10 bg-white shadow",attrs:{"id":"bottom-navigation"}},[_c('div',{staticClass:"flex justify-between text-dark-gray",attrs:{"id":"tabs"}},[(
        !(
          _vm.currentSession &&
          _vm.currentSession.deactivatedMenuItems &&
          _vm.currentSession.deactivatedMenuItems.includes('reactions')
        ) && !_vm.hideReactionMenuItem
      )?_c('router-link',{staticClass:"w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1 cursor-pointer",attrs:{"to":"reactions"},nativeOn:{"click":function($event){return _vm.clickHandler('reactions')}}},[_c('svg',{staticClass:"inline-block mb-1",style:({
          color:
            _vm.selectedMenuItem === 'reactions' ? _vm.theme.buttonColor : '#C2CAD5',
        }),attrs:{"width":"25","height":"25","viewBox":"0 0 20 20","fill":"currentColor","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z"}})]),_c('span',{staticClass:"tab tab-kategori block text-xs",style:({
          color:
            _vm.selectedMenuItem === 'reactions' ? _vm.theme.buttonColor : '#989EB2',
        })},[_vm._v(" "+_vm._s(_vm.$t('components.viewer.bottomMenuReactions'))+" ")])]):_vm._e(),(
        !(
          _vm.currentSession &&
          _vm.currentSession.deactivatedMenuItems &&
          _vm.currentSession.deactivatedMenuItems.includes('questions')
        )
      )?_c('router-link',{staticClass:"relative w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1",attrs:{"to":"questions"},nativeOn:{"click":function($event){return _vm.clickHandler('questions')}}},[(!_vm.$route.path.includes('questions') && _vm.newQuestionCount > 0)?_c('span',{staticClass:"absolute -top-1 -right-1 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-primary text-white"},[_vm._v(" "+_vm._s(_vm.newQuestionCount)+" ")]):_vm._e(),_c('svg',{staticClass:"inline-block mb-1",style:({
          color:
            _vm.selectedMenuItem === 'questions' ? _vm.theme.buttonColor : '#C2CAD5',
        }),attrs:{"width":"25","height":"25","fill":"currentColor","viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z"}}),_c('path',{attrs:{"d":"M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z"}})]),_c('span',{staticClass:"tab tab-explore block text-xs",style:({
          color:
            _vm.selectedMenuItem === 'questions' ? _vm.theme.buttonColor : '#989EB2',
        })},[_vm._v(" "+_vm._s(_vm.currentSession && _vm.currentSession.labels && _vm.currentSession.labels.questions ? (_vm.currentSession.labels.questions.length > 12 && _vm.currentSession.labels.questions.substring(0, 12)) || _vm.currentSession.labels.questions : _vm.$t('components.viewer.bottomMenuQuestions'))+" ")])]):_vm._e(),(
        !(
          _vm.currentSession &&
          _vm.currentSession.deactivatedMenuItems &&
          _vm.currentSession.deactivatedMenuItems.includes('polls')
        )
      )?_c('router-link',{staticClass:"relative w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1",attrs:{"to":"polls"},nativeOn:{"click":function($event){return _vm.clickHandler('polls')}}},[(!_vm.$route.path.includes('polls') && _vm.newPollCount > 0)?_c('span',{staticClass:"absolute -top-1 -right-1 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-primary text-white"},[_vm._v(" "+_vm._s(_vm.newPollCount)+" ")]):_vm._e(),_c('svg',{staticClass:"inline-block mb-1",style:({
          color: _vm.selectedMenuItem === 'polls' ? _vm.theme.buttonColor : '#C2CAD5',
        }),attrs:{"width":"25","height":"25","viewBox":"0 0 20 20","fill":"currentColor","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"}}),_c('path',{attrs:{"d":"M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"}})]),_c('span',{staticClass:"tab tab-explore block text-xs",style:({
          color: _vm.selectedMenuItem === 'polls' ? _vm.theme.buttonColor : '#989EB2',
        })},[_vm._v(" "+_vm._s(_vm.currentSession && _vm.currentSession.labels && _vm.currentSession.labels.polls ? (_vm.currentSession.labels.polls.length > 12 && _vm.currentSession.labels.polls.substring(0, 12)) || _vm.currentSession.labels.polls : _vm.$t('components.viewer.bottomMenuPolls'))+" ")])]):_vm._e(),(
        !(
          _vm.currentSession &&
          _vm.currentSession.deactivatedMenuItems &&
          _vm.currentSession.deactivatedMenuItems.includes('wordclouds')
        )
      )?_c('router-link',{staticClass:"w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1",attrs:{"to":"wordclouds"},nativeOn:{"click":function($event){return _vm.clickHandler('wordclouds')}}},[_c('svg',{staticClass:"inline-block mb-1",style:({
          color:
            _vm.selectedMenuItem === 'wordcloud' ? _vm.theme.buttonColor : '#C2CAD5',
        }),attrs:{"width":"25","height":"25","fill":"currentColor","viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M5.5 16a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 16h-8z"}})]),_c('span',{staticClass:"tab tab-explore block text-xs",style:({
          color:
            _vm.selectedMenuItem === 'wordcloud' ? _vm.theme.buttonColor : '#989EB2',
        })},[_vm._v(" "+_vm._s(_vm.currentSession && _vm.currentSession.labels && _vm.currentSession.labels.wordcloud ? (_vm.currentSession.labels.wordcloud.length > 12 && _vm.currentSession.labels.wordcloud.substring(0, 12)) || _vm.currentSession.labels.wordcloud : _vm.$t('components.viewer.bottomMenuWordcloud'))+" ")])]):_vm._e(),_c('router-link',{staticClass:"w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1",attrs:{"to":"info"},nativeOn:{"click":function($event){return _vm.clickHandler('info')}}},[_c('svg',{staticClass:"inline-block mb-1",style:({
          color: _vm.selectedMenuItem === 'info' ? _vm.theme.buttonColor : '#C2CAD5',
        }),attrs:{"width":"25","height":"25","viewBox":"0 0 20 20","fill":"currentColor","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z","clip-rule":"evenodd"}})]),_c('span',{staticClass:"tab tab-explore block text-xs",style:({
          color: _vm.selectedMenuItem === 'info' ? _vm.theme.buttonColor : '#989EB2',
        })},[_vm._v(_vm._s(_vm.$t('components.viewer.bottomMenuInfo')))])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }