



































































































































































































































































































import Vue from 'vue';
import { millisToTimeString } from '@/utils/tools';
import BottomMenu from '@/components/Viewer/BottomMenu.vue';
import { mapGetters } from 'vuex';
import ThemeModel from '@/dtos/theme';
import LangSwitch from '@/components/LangSwitch.vue';

export default Vue.extend({
  name: 'SessionWrapper',
  components: { BottomMenu, LangSwitch },
  props: {
    activeSession: {
      type: Object,
      required: true,
    },
    selectedMenuItem: {
      type: String,
      required: true,
    },
    hideNavMenu: {
      type: Boolean,
      required: false,
      default: false,
    },
    sessionHasEnded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return { now: new Date() };
  },

  updated() {
    this.clickHandler(this.selectedMenuItem);
  },
  computed: {
    ...mapGetters({
      sessionTheme: 'viewer/getSessionTheme',
      userId: 'auth/getUserId',
      newQuestionCount: 'viewer/getNewQuestionCount',
      newPollCount: 'viewer/getNewPollCount',
      hideHeader: 'viewer/getHideHeader',
      hideReactionMenuItem: 'viewer/getHideReactionMenuItem',
    }),
    theme(): ThemeModel {
      return this.sessionTheme as ThemeModel;
    },
    elapsedTime(): string {
      if (this.activeSession) {
        const elapsedTime =
          this.now.getTime() - this.activeSession.startAt.toDate().getTime();
        return millisToTimeString(elapsedTime);
      }
      return '-';
    },
    remainingTime(): string {
      if (this.activeSession) {
        const remainingTime =
          this.activeSession.endAt.toDate().getTime() - this.now.getTime();
        return millisToTimeString(remainingTime);
      }
      return '-';
    },
    navItems(): any[] {
      const items: any[] = [
        {
          routeName: 'reactions',
          name: this.$t('components.viewer.sessionWrapperReactions'),
        },
        {
          routeName: 'questions',
          name:
            this.activeSession &&
            this.activeSession.labels &&
            this.activeSession.labels.questions
              ? (this.activeSession.labels.questions.length > 12 &&
                  this.activeSession.labels.questions.substring(0, 12)) ||
                this.activeSession.labels.questions
              : this.$t('components.viewer.sessionWrapperQuestions'),
        },
        {
          routeName: 'polls',
          name:
            this.activeSession &&
            this.activeSession.labels &&
            this.activeSession.labels.polls
              ? (this.activeSession.labels.polls.length > 12 &&
                  this.activeSession.labels.polls.substring(0, 12)) ||
                this.activeSession.labels.polls
              : this.$t('components.viewer.sessionWrapperPolls'),
        },
        {
          routeName: 'wordclouds',
          name:
            this.activeSession &&
            this.activeSession.labels &&
            this.activeSession.labels.wordcloud
              ? (this.activeSession.labels.wordcloud.length > 12 &&
                  this.activeSession.labels.wordcloud.substring(0, 12)) ||
                this.activeSession.labels.wordcloud
              : this.$t('components.viewer.sessionWrapperWordcloud'),
        },
        {
          routeName: 'info',
          name: this.$t('components.viewer.sessionWrapperInfo'),
        },
      ];
      const res = [];
      if (this.activeSession && this.activeSession.deactivatedMenuItems) {
        for (const item of items) {
          if (
            !this.activeSession.deactivatedMenuItems.includes(item.routeName)
          ) {
            res.push(item);
          }
        }
      }
      return res.length > 0 ? res : items;
    },
  },
  methods: {
    clickHandler(routeName: string) {
      switch (routeName) {
        case 'questions': {
          this.$store.dispatch('viewer/resetQuestionCount');
          return;
        }

        case 'polls': {
          this.$store.dispatch('viewer/resetPollCount');
          return;
        }

        default: {
          return;
        }
      }
    },
  },
});
